import {HelperUrl} from "./Helper.Url";
import {namespace} from "../namespace";
import {HelperUser} from "./Helper.User";
import $ from "jquery";
import {mergeDeep, resolveUrl} from "./helper";

type oDataStorageOption = {
	provider: "oData",
	oDataServiceHost: string,
	queryCache: boolean,
	UpdateMethod: string
}

type localStorageOption = {
	provider: "local",
	databaseName: string,
	maxSize: number,
	dbCreation: any,
	queryCache: boolean
}

type indexedDbStorageOption = {
	provider: "indexedDb",
	databaseName: string
}

type webApiStorageOption = {
	provider: "webApi",
}

type StorageOptions = oDataStorageOption | localStorageOption | indexedDbStorageOption | webApiStorageOption

class HelperDatabase {
	private static initializing = false;
	// TODO rewrite this to a Promise
	private static initializeDeferred = $.Deferred();
	static dbDefinition = {};
	static dbSchema = {};
	static converters = {};
	static dbIndicesMultiEntry = {};
	static dbIndicesSingleEntry = {};
	static globalFilterFunctions = [];
	static transactionIdFunctions = {};

	private static applyGlobalFilters(query: { expression: any; context: any; }): void {
		const globalFilters = HelperDatabase.globalFilterFunctions
			.map(globalFilterFunction => globalFilterFunction())
			.filter(globalFilter => !!globalFilter)
			.map(globalFilter => {
				globalFilter.filterScope = globalFilter.filterScope || {};
				globalFilter.requiredColumns = globalFilter.requiredColumns || [];
				return globalFilter;
			});
		let expressionSource = query.expression;
		let tmp = null;
		let entitySetExpression = null;
		const includeExpressions = [];

		while (expressionSource) {
			if (expressionSource instanceof ($data as any).Expressions.EntitySetExpression) {
				entitySetExpression = expressionSource;
			}
			if (expressionSource instanceof ($data as any).Expressions.IncludeExpression) {
				includeExpressions.unshift(expressionSource);
			}
			Object.defineProperty(expressionSource, "parentExpressionSource", {
				enumerable: false,
				writable: true,
				value: tmp
			});
			tmp = expressionSource;
			expressionSource = expressionSource.source;
		}

		const buildPathFromExpressionTree = (expression: any, path = "") => {
			if (expression instanceof ($data as any).Expressions.ConstantExpression) {
				return expression.value;
			}
			if (expression instanceof ($data as any).Expressions.EntityExpression && expression.selector && expression.selector.lambda !== "it") {
				path = expression.source.source.storageModel.Associations.find(function (it) {
					return it.FromType === expression.source.source.entityType && it.ToType === expression.entityType;
				}).FromPropertyName + "." + path;
				return buildPathFromExpressionTree(expression.source.source, path);
			} else if (expression instanceof ($data as any).Expressions.EntitySetExpression) {
				path = expression.source.storageModel.Associations.find(function (it) {
					return it.FromType === (expression.source.entityType || expression.source.elementType) && it.ToType === expression.elementType;
				}).FromPropertyName + "." + path;
				return buildPathFromExpressionTree(expression.source, path);
			} else if (expression instanceof ($data as any).Expressions.FrameOperationExpression) {
				return buildPathFromExpressionTree(expression.source, path);
			}
			return path.replace(/\.$/, "");
		};

		function getFilter(elementType) {
			const elementTypeFilters = globalFilters.filter(function (globalFilter) {
				return globalFilter.requiredColumns.every(elementType.getMemberDefinition.bind(elementType));
			});
			if (elementTypeFilters.length === 0) {
				return null;
			}
			const filterScopes = elementTypeFilters.map(function (elementTypeFilter) {
				return elementTypeFilter.filterScope;
			});
			filterScopes.unshift({});
			return {
				filter: "function(it){return " + elementTypeFilters.map(function (elementTypeFilter) {
					return "(" + elementTypeFilter.filter + ")";
				}).join(" && ") + ";}",
				// @ts-ignore
				filterScope: Object.assign.apply(this, filterScopes)
			};
		}

		function setSourceEntityType(path, expression) {
			for (let j = 0; j < path.length; j++) {
				expression = expression.source;
			}
			expression.entityType = expression.entityType || expression.elementType;
		}

		const entitySetFilter = entitySetExpression != null ? getFilter(entitySetExpression.elementType) : null;
		if (entitySetExpression != null && entitySetFilter != null) {
			entitySetExpression.parentExpressionSource.source = ($data as any).Container.createQueryExpressionCreator(query.context).Visit(($data as any).Container.createFilterExpression(entitySetExpression, ($data as any).Container.createCodeExpression(entitySetFilter.filter, entitySetFilter.filterScope)));
			entitySetExpression.parentExpressionSource = entitySetExpression.parentExpressionSource.source;
		}

		const filterPath = {};
		includeExpressions.forEach(includeExpression => {
			let entitySet = entitySetExpression.instance;
			const context = entitySet.entityContext;
			const includePath = buildPathFromExpressionTree(includeExpression.selector.expression || includeExpression.selector);
			const path = includePath.split(".");

			for (let i = 0; i < path.length; i++) {
				const sm = context._storageModel.getStorageModel(entitySet.elementType);
				if (sm) {
					const associations = sm.Associations;
					const current = associations[path[i]];
					const currentFilter = getFilter(current.ToType);
					const navigationPath = path.slice(0, i + 1).join(".");
					let filter;
					if (current.ToMultiplicity !== "*" && !filterPath[navigationPath] && currentFilter != null) {
						filter = currentFilter.filter.replace(/it\./g, "it." + navigationPath + ".");
						entitySetExpression.parentExpressionSource.source = ($data as any).Container.createQueryExpressionCreator(query.context)
							.Visit(($data as any).Container.createFilterExpression(entitySetExpression, ($data as any).Container.createCodeExpression(filter, currentFilter.filterScope)));
						entitySetExpression.parentExpressionSource = entitySetExpression.parentExpressionSource.source;
						filterPath[navigationPath] = true;
					} else if (i === path.length - 1 && current.ToMultiplicity === "*" && currentFilter != null) {
						filter = "it." + includePath + ".filter(" + currentFilter.filter.replace(/\(it\)/, "(" + includePath.replace(/\./g, "__") + ")").replace(/it\./g, includePath.replace(/\./g, "__") + ".") + ")";
						const selector = ($data as any).Container.createQueryExpressionCreator(query.context)
							.Visit(($data as any).Container.createIncludeExpression(entitySetExpression, ($data as any).Container.createCodeExpression(filter, currentFilter.filterScope))).selector;
						if (includeExpression.selector instanceof ($data as any).Expressions.ConstantExpression) {
							includeExpression.selector = selector;
							setSourceEntityType(path, includeExpression.selector.expression);
						} else if (includeExpression.selector instanceof ($data as any).Expressions.ParametricQueryExpression) {
							let lastFrameOperationExpression = includeExpression.selector.expression;
							while (lastFrameOperationExpression.source instanceof ($data as any).Expressions.FrameOperationExpression) {
								lastFrameOperationExpression = lastFrameOperationExpression.source;
							}
							lastFrameOperationExpression.source = selector.expression;
							setSourceEntityType(path, lastFrameOperationExpression.source);
						}
					}
					entitySet = context.getEntitySetFromElementType(current.ToType);
				}
			}
		});
	}

	public static getKeyProperty(storageKey: string): string {
		if (!!window.database[storageKey]
			&& !!window.database[storageKey].defaultType
			&& !!window.database[storageKey].defaultType.memberDefinitions
			&& window.database[storageKey].defaultType.memberDefinitions.getKeyProperties().length === 1) {
			return window.database[storageKey].defaultType.memberDefinitions.getKeyProperties()[0].name;
		} else {
			return "Id";
		}
	}

	public static getFromStorage(storageKey: string, params: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }, successMethod: { (result: any): void; (arg0: any): void; }): void {
		const start = performance.now();
		window.Log.debug("Helper.Offline.js: getFromStorage (storageKey, params successMethod) with storageKey = " + storageKey);
		const keyProperty = HelperDatabase.getKeyProperty(storageKey);
		if (!!params[keyProperty]) {
			window.Log.debug("Helper.Offline.js: getFromStorage (storageKey, params successMethod) searching by " + keyProperty + " = " + params[keyProperty]);
			window.Crm.Offline.Database[storageKey].find(params[keyProperty]).then(function (result) {
				window.Log.debug(`Helper.Offline.js: getFromStorage finished after ${performance.now() - start} ms`);
				successMethod(result.asKoObservable());
			}).catch(function () {
				successMethod(null);
			});
		} else {
			let query = window.Crm.Offline.Database[storageKey];
			for (const paramName in params) {
				if (params.hasOwnProperty(paramName)) {
					if (Array.isArray(params[paramName])) {
						query = query.filter("it." + paramName + " in this.value", {value: params[paramName]});
					} else {
						query = query.filter("it." + paramName + " == this.value", {value: params[paramName]});
					}
				}
			}
			query.toArray(results => {
				window.Log.debug(`Helper.Offline.js: getFromStorage finished after ${performance.now() - start} ms`);
				successMethod(results.map(result => result.asKoObservable()));
			});
		}
	}

	public static addGlobalFilter(globalFilterFunction: any): void {
		HelperDatabase.globalFilterFunctions.push(globalFilterFunction);
	}

	public static addIndex(storageKey: string, index: any[]): void {
		(HelperDatabase.dbIndicesMultiEntry)[storageKey] = (HelperDatabase.dbIndicesMultiEntry)[storageKey] || [];
		(HelperDatabase.dbIndicesSingleEntry)[storageKey] = (HelperDatabase.dbIndicesSingleEntry)[storageKey] || [];
		const indexNameMultiEntry = "IX_" + storageKey + "_" + index.join("_");
		if (HelperDatabase.dbIndicesMultiEntry[storageKey].filter((x: any) => x.name === indexNameMultiEntry).length === 0) {
			(HelperDatabase.dbIndicesMultiEntry)[storageKey].push({name: indexNameMultiEntry, keys: index});
		}
		index.forEach((indexColumn) => {
			const indexNameSingleEntry = "IX_" + storageKey + "_" + indexColumn;
			if (HelperDatabase.dbIndicesSingleEntry[storageKey].filter((x: any) => x.name === indexNameSingleEntry).length === 0) {
				(HelperDatabase.dbIndicesSingleEntry)[storageKey].push({
					name: indexNameSingleEntry,
					keys: [indexColumn]
				});
			}
		});
	}

	public static clearTrackedEntities(): void {
		if (window.database) {
			window.database.stateManager.trackedEntities.splice(0, window.database.stateManager.trackedEntities.length);
		}
	}

	// TODO: use jaydata with current provider directly
	public static get(options, params, storageKey: string, callback): void {
		const start = performance.now();
		window.Log.debug("Helper.Offline.get");
		params = params || [];
		if (storageKey == null) {
			throw new Error("Helper.Database.get: storageKey is null");
		}
		HelperDatabase.getFromStorage(storageKey, params, result => {
			window.Log.debug(`Helper.Offline.get finished after ${performance.now() - start} ms`);
			callback(result);
		});
	}

	public static getFromLocalStorage(keyName: string): string {
		keyName = HelperDatabase.getStoragePrefix() + keyName;
		return window.localStorage.getItem(keyName);
	}

	public static getLocalStorageKeys(): string[] {
		const prefix = HelperDatabase.getStoragePrefix();
		return Object.keys(window.localStorage)
			.filter(key => key.startsWith(prefix))
			.map(key => key.substring(prefix.length));
	}

	public static getStoragePrefix(): string {
		const usePrefix = window.localStorage.getItem("SchemaInfo") === null;
		let virtualPath = HelperUrl.resolveUrl("~");
		if (!usePrefix || !virtualPath) {
			return "";
		}
		virtualPath = virtualPath.startsWith("/") ? virtualPath.substring(1) : virtualPath;
		return virtualPath + "_";
	}

	public static hasProperty(storageKey: string, propertyName: string): boolean {
		const schema = HelperDatabase.getSchema(storageKey);
		return !!schema[propertyName];
	}

	public static remove(element, options, storageKey, callback): JQuery.Promise<void> {
		const d = $.Deferred();
		if (!storageKey) {
			d.reject("Helper.Database.remove: storageKey must not be null");
			return d.promise();
		}
		HelperDatabase.removeFromStorage(element, storageKey, function () {
			d.resolve();
			if (typeof callback === "function") {
				console.warn("callback parameter is deprecated, handle returned promise instead");
				callback();
			}
		});
		return d.promise();
	}

	public static removeFromLocalStorage(keyName: string): void {
		keyName = HelperDatabase.getStoragePrefix() + keyName;
		window.localStorage.removeItem(keyName);
	}

	public static save(element: any, _: any, storageKey: string, callback: () => void): JQuery.Promise<any> {
		const d = $.Deferred();
		if (!storageKey) {
			d.reject("Helper.Database.save: storageKey must not be null");
			return d.promise();

		}
		const start = performance.now();
		window.Log.debug("Helper.Offline.js: save");
		HelperDatabase.checkIfTransient(element, storageKey)
			.then(isTransient => {
				if (isTransient) {
					window.database[storageKey].add(element.innerInstance);
				} else {
					window.database[storageKey].attachOrGet(element.innerInstance);
					element.innerInstance.changedProperties = [...element.innerInstance.getType().memberDefinitions.getPublicMappedProperties()];
					element.innerInstance.entityState = $data.EntityState.Modified;
				}
				window.database[storageKey].saveChanges().then(function () {
					window.Log.debug(`Helper.Offline.js: save done after ${performance.now() - start} ms`);
					d.resolve();
					if (typeof callback === "function") {
						console.warn("callback parameter is deprecated, handle returned promise instead");
						callback();
					}
				}).fail(d.reject);
			}).catch(d.reject);
		return d.promise();
	}

	public static saveToLocalStorage(keyName: string, value: string): void {
		keyName = HelperDatabase.getStoragePrefix() + keyName;
		window.localStorage.setItem(keyName, value);
	}

	public static registerTable(storageKey: string, columns: any, indices: any[] = []): void {
		const schema = mergeDeep(columns, HelperDatabase.dbSchema[storageKey] || {});
		HelperDatabase.dbSchema[storageKey] = {};
		Object.getOwnPropertyNames(schema)
			.sort()
			.forEach((column) => {
				HelperDatabase.dbSchema[storageKey][column] = schema[column];
			});
		indices ||= [];
		indices.forEach((index) => {
			HelperDatabase.addIndex(storageKey, index);
		});
	}

	public static registerConverter(targetTypeName: string, sourceTypeName: string, options: any): void {
		options = options || {};
		options["sourceTypeName"] = sourceTypeName;
		HelperDatabase.converters[targetTypeName] = options;
	}

	public static registerDependency(entity: any, dependency: any): void {
		entity = HelperDatabase.getDatabaseEntity(entity);
		dependency = HelperDatabase.getDatabaseEntity(dependency);
		if (dependency.entityState === $data.EntityState.Detached) {
			return;
		}
		entity.dependentOn = entity.dependentOn || [];
		if (entity.dependentOn.indexOf(dependency) === -1) {
			entity.dependentOn.push(dependency);
		}

		const index = (dependency.dependentOn || []).indexOf(entity);
		if (index !== -1) {
			dependency.dependentOn.splice(index, 1);
		}
	}

	public static initialize(): JQuery.Deferred<void> {
		if (HelperDatabase.initializing === false) {
			HelperDatabase.initializing = true;
			let initPromise: Promise<void> = Promise.resolve();
			if (($data as any).storageProviders.oData) {
				initPromise = window.AuthorizationManager.initPromise()
					.then(() => ($data as any).initService(HelperDatabase.getODataStorageOptions().oDataServiceHost))
					.then((context) => {
						context.storageProvider.providerConfiguration.UpdateMethod = "PUT";
						context.storageProvider.providerConfiguration.sendAllPropertiesOnChange = true;
						HelperDatabase.configureStorageModel(context);
						// @ts-ignore
						window.oDataDatabase = context;
					});
			}
			initPromise = initPromise.then(async () => {
				const options = HelperDatabase.getStorageOptions();
				if (options.provider === "oData") {
					// @ts-ignore
					namespace("Crm.Offline").Database = window.oDataDatabase;
					HelperDatabase.dispatchDatabaseInitializedEvent();
					return null;
				}
				await HelperDatabase.createSchemaFromDefinitions();
				for (const storageKey in HelperDatabase.dbSchema) {
					if (HelperDatabase.dbSchema.hasOwnProperty(storageKey)) {
						HelperDatabase.createStorage(storageKey);
					}
				}
				const identity = x => x;
				Object.keys(HelperDatabase.converters).forEach(targetTypeName => {
					const options = HelperDatabase.converters[targetTypeName];
					const targetExtension = options.targetExtension || {};
					const toConverter = options.toConverter || identity;
					const fromConverter = options.fromConverter || identity;
					const sourceType = window.Crm.Offline.DatabaseModel[options.sourceTypeName];
					if (sourceType) {
						const targetType = sourceType.extend(targetTypeName, targetExtension);
						($data as any).Container.registerConverter(targetType, sourceType, toConverter, fromConverter);
					}
				});
				let definitions = await HelperDatabase.getDefinitions();
				let map = definitions.reduce((map, def) => {
					map[def.table] = null;
					return map;
				}, {});
				Object.keys(HelperDatabase.dbDefinition).forEach(key => {
					if (key === "Main_RecentPage" || key === "Main_NumberingSequence") {
						return;
					}
					if (map.hasOwnProperty(key) === false) {
						delete HelperDatabase.dbDefinition[key];
					}
				});
				window.Crm.Offline.LmobileDatabase = $data.EntityContext.extend("window.Crm.Offline.LmobileDatabase", HelperDatabase.dbDefinition);
				const getMember = window.Crm.Offline.LmobileDatabase.memberDefinitions.getMember;
				window.Crm.Offline.LmobileDatabase.memberDefinitions.getMember = function (name) {
					if (HelperDatabase.converters[name]) {
						return getMember.call(this, HelperDatabase.converters[name].sourceTypeName);
					}
					return getMember.apply(this, arguments);
				};
				return HelperDatabase.tryInitDatabase();
			});

			initPromise.then(() => {
				const saveChanges = window.database.saveChanges;
				window.database.saveChanges = function () {
					// @ts-ignore
					return saveChanges.apply(this, arguments).fail(e => window.Log.error(e));
				};
				HelperDatabase.EventListeners.attach();
				HelperDatabase.initializeDeferred.resolve();
			}).catch(HelperDatabase.initializeDeferred.reject);
		}
		return HelperDatabase.initializeDeferred;
	}

	public static getRawDefinitions(): JQuery.jqXHR {
		return $.ajax(HelperUrl.resolveUrl("~/Model/GetDefinitions"), {method: "GET", cache: true});
	}

	public static getIndices(storageKey: string): any[] {
		const indices = HelperDatabase.isMultiEntryIndexSupported() ? (HelperDatabase.dbIndicesMultiEntry)[storageKey] : (HelperDatabase.dbIndicesSingleEntry)[storageKey];
		return indices || [];
	}

	public static getSchema(storageKey: string): any {
		return storageKey ? (HelperDatabase.dbSchema)[storageKey] || null : HelperDatabase.dbSchema;
	}

	public static async getTransactionIds(storageKey: string, element: any): Promise<any[]> {
		HelperDatabase.transactionIdFunctions[storageKey] ||= [];
		return (await Promise.all(HelperDatabase.transactionIdFunctions[storageKey].map(f => f(element)))).flat();
	}

	public static setTransactionId(storageKey: string, transactionIdFunction) {
		(HelperDatabase.transactionIdFunctions[storageKey] ??= []).push(transactionIdFunction);
	}

	public static async registerEventHandlers(viewModel: any, tableEventHandlers: any): Promise<void> {
		await HelperDatabase.initializeDeferred;
		const eventHandlers = {};
		for (const table of Object.keys(tableEventHandlers)) {
			if (!window.database[table]) {
				window.Log.warn(`Failed registering event handlers for table ${table}`);
				continue;
			}
			eventHandlers[table] ??= {};
			const tableEventHandler = tableEventHandlers[table];
			for (const event of Object.keys(tableEventHandler)) {
				eventHandlers[table][event] ??= {};
				const handler = tableEventHandler[event];
				if (handler) {
					eventHandlers[table][event] = handler.bind(viewModel);
					if (window.database[table].defaultType) {
						window.database[table].defaultType.addEventListener(event, eventHandlers[table][event]);
					}
				}
			}
		}
		const baseDispose = viewModel.dispose;
		viewModel.dispose = function (): void {
			if (typeof baseDispose === "function") {
				// eslint-disable-next-line prefer-rest-params
				baseDispose.apply(viewModel, arguments);
			}
			for (const table of Object.keys(eventHandlers)) {
				for (const event of Object.keys(eventHandlers[table])) {
					const handler = eventHandlers[table][event];
					const defaultType = window.database[table].defaultType;
					if (handler && defaultType) {
						defaultType.removeEventListener(event, handler);
					}
				}
			}
		};
	}

	public static getDatabaseEntity(obj): any {
		const value = ko.unwrap(obj);
		if (value instanceof ($data as any).KoObservableEntity) {
			return value.innerInstance;
		} else if (value === null || value === undefined || value instanceof $data.Entity) {
			return value;
		}
		throw new Error("unknown type");
	}

	public static transferData(keys: string[], src: { [x: string]: string | any[]; }, dst: { [x: string]: any; }): void {
		(keys || []).forEach(key => {
			if (!["ExtensionValues", "localTimestamp", "ItemStatus"].includes(key) && !(src[key] instanceof Array)) {
				dst[key] = src[key];
			} else if (src[key] instanceof Array) {
				dst[key] = src[key].slice();
			}
		});
	}

	public static createClone(data: any): any {
		const entity = HelperDatabase.getDatabaseEntity(data);
		if (entity === null) {
			return null;
		}
		const type = entity.getType();
		const clone = type.create(entity);
		HelperDatabase.transferData(Object.keys(entity.initData), entity, clone);
		clone.ItemStatus = entity.ItemStatus;
		clone.resetChanges();
		if (entity.ExtensionValues && clone.ExtensionValues) {
			clone.ExtensionValues = this.createClone(entity.ExtensionValues);
		}
		return clone;
	}

	public static EventListeners = {
		"beforeCreate": {
			"BeforeCreateEventListener": (sender, items) => {
				items = Array.isArray(items) ? items : [items];
				items.forEach(function (item) {
					const user = HelperUser.getCurrentUserName();
					const date = new Date();
					item.CreateDate = date;
					item.CreateUser = user;
					item.ModifyDate = date;
					item.ModifyUser = user;
					item.IsActive = true;
				});
			}
		},
		"afterCreate": {},
		"beforeUpdate": {
			BeforeUpdateEventListener: function (sender, items) {
				items = Array.isArray(items) ? items : [items];
				items.forEach(function (item) {
					item.ModifyDate = new Date();
					item.ModifyUser = HelperUser.getCurrentUserName();
				});
			}
		},
		"afterUpdate": {},
		"beforeDelete": {},
		"afterDelete": {},
		attached: false,
		attach() {
			if (HelperDatabase.EventListeners.attached) {
				return;
			}
			const storedEntityNames = Array.from(new Set((window.database as any)._storageModel.map(storageModel => storageModel.ItemName)));
			Object.keys(HelperDatabase.EventListeners).forEach(event => {
				const eventObject = HelperDatabase.EventListeners[event];
				if (eventObject instanceof Object) {
					Object.keys(eventObject).forEach(listener => {
						HelperDatabase.EventListeners.attachListener(storedEntityNames, event, listener);
					});
				}
			});
			HelperDatabase.EventListeners.attached = true;
		},
		attachListener(storedEntityNames, event, listener) {
			const obj = HelperDatabase.EventListeners[event][listener];
			let listenerFunc;
			let condition = function (...args: any[]) {
				return true;
			};
			if (typeof obj === "function") {
				listenerFunc = obj;
			} else if (typeof obj.listener === "function") {
				listenerFunc = obj.listener;
				if (typeof obj.condition === "function") {
					condition = obj.condition;
				}
			} else {
				throw Error("unsupported listener format");
			}
			storedEntityNames.forEach(function (entityName) {
				if (condition(entityName)) {
					window.database[entityName].elementType.addEventListener(event, listenerFunc);
				}
			});
		}
	};

	public static hasPendingChanges(): boolean {
		if (!window.database) {
			return false;
		}
		let result = false;
		for (const entity of window.database.stateManager.trackedEntities) {
			if (entity.data._entityState === $data.EntityState.Added) {
				for (const changedProperty of (entity.data.changedProperties || [])) {
					if (entity.data[changedProperty.name]) {
						result = true;
					}
				}
			} else if (entity.data._entityState === $data.EntityState.Modified || entity.data._entityState === $data.EntityState.Deleted) {
				result = true;
			}
		}
		return result;
	}

	private static getElementId(element: any, storageKey: string): any {
		window.Log.debug("Helper.Offline.js: calling get element id");
		const keyProperty = HelperDatabase.getKeyProperty(storageKey);
		window.Log.debug("Helper.Offline.js: calling get element id, found key property " + keyProperty);
		if (!!element[keyProperty] && element[keyProperty] !== 0 && element[keyProperty] !== "00000000-0000-0000-0000-000000000000") {
			window.Log.debug("Helper.Offline.js: calling get element id, found key property " + keyProperty + " with value " + element[keyProperty]);
			return element[keyProperty];
		} else {
			window.Log.debug("Helper.Offline.js: calling get element id, transient instance, returning null");
			return null;
		}
	}

	private static findElementInStorage(element: any, storageKey: string, callback: (elementInStorage) => void): void {
		const start = performance.now();
		window.Log.debug("Helper.Offline.js: findElementInStorage");
		const elementId = HelperDatabase.getElementId(element, storageKey);
		if (!!elementId) {
			window.Crm.Offline.Database[storageKey].find(elementId).then(result => {
				window.Log.debug(`Helper.Offline.js: findElementInStorage done after ${performance.now() - start} ms`);
				callback(result);
			}).catch(function () {
				window.Log.debug(`Helper.Offline.js: findElementInStorage done, element not found after ${performance.now() - start} ms`);
				callback(null);
			});
		} else {
			window.Log.warn("Helper.Offline.js: findElementInStorage failed, element has no id");
			callback(null);
		}
	}

	private static removeFromStorage(element: any, storageKey: string, callback: () => void): void {
		window.Log.debug("window.Helper.Offline: removeFromStorage");
		HelperDatabase.findElementInStorage(element, storageKey, elementInStorage => {
			if (elementInStorage != null) {
				window.Crm.Offline.Database[storageKey].remove(elementInStorage);
				window.Crm.Offline.Database[storageKey].saveChanges()
					.done(() => {
						window.Log.debug("removing done");
						callback();
					})
					.catch(e => {
						const errorMessage = "removeFromStorage failed: " + e.message;
						window.Log.error(errorMessage);
					});
			} else {
				window.Log.debug("removing skipped (element not found in storage)");
				callback();
			}
		});
	}

	private static configureStorageModel(storageModel: any): void {
		const baseAdd = storageModel.add;
		storageModel.add = function (entity) {
			if (entity instanceof ($data as any).EntityWrapper) {
				entity = entity.getEntity();
			}
			const entitySet = this.getEntitySetFromElementType(entity.getType());
			const keyProperties = entitySet.elementType.memberDefinitions.getKeyProperties();
			const isGuidType = keyProperties.length === 1 && keyProperties[0].dataType === ($data as any).Guid;
			const idProperty = keyProperties[0].name;
			if (isGuidType && entity[idProperty] === "00000000-0000-0000-0000-000000000000") {
				entity[idProperty] = window.$data.createGuid().toString().toLowerCase();
			}
			// eslint-disable-next-line prefer-rest-params
			return baseAdd.apply(this, arguments);
		};
	}

	private static initDatabase(): Promise<void> {
		namespace("window.Crm.Offline").Database = new window.Crm.Offline.LmobileDatabase(HelperDatabase.getStorageOptions());
		HelperDatabase.configureStorageModel(window.Crm.Offline.Database);
		return this.onReadyPromise();
	}

	private static tryInitDatabase(): Promise<void> {
		if (!namespace("window.Crm.Offline").Database || window.Crm.Offline.Database._isOK || typeof window.Crm.Offline.Database.onReady !== "function") {
			window.Log.debug("tryInitDatabase: calling initDatabase directly");
			return HelperDatabase.initDatabase();
		} else {
			return this.onReadyPromise();
		}
	}

	private static getKeyPropertyFromDbSchema(storageKey: string): string {
		const schema = HelperDatabase.getSchema(storageKey);
		for (const column in schema) {
			if (schema.hasOwnProperty(column)) {
				if (schema[column].key) {
					return column;
				}
			}
		}
		return "Id";
	}

	private static onReadyPromise(): Promise<void> {
		return new Promise((resolve, reject) => {
			window.Crm.Offline.Database.onReady({
				success: () => {
					window.Helper.Database.dispatchDatabaseInitializedEvent();
					resolve();
				},
				error: e => {
					window.Crm.Offline.Database = null;
					window.Log.error("LmobileDatabase could not be initialized: " + (e.message || e));
					reject(e);
				}
			});
		});
	}

	private static replaceArrayDefaultValues(definition: any): void {
		for (const x of Object.getOwnPropertyNames(definition)) {
			if (Array.isArray(definition[x].defaultValue) && definition[x].defaultValue.length === 0) {
				definition[x].defaultValue = () => [];
			}
		}
	}

	private static removeUnknownCollectionProperties(definition: any): void {
		for (const x of Object.getOwnPropertyNames(definition)) {
			const isCollectionProperty = definition[x].elementType && definition[x].elementType.indexOf("Crm.Offline.DatabaseModel") !== -1;
			if (!isCollectionProperty) {
				continue;
			}
			const schema: string = HelperDatabase.getSchema((definition[x].elementType as string).substring(26));
			const hasKeyProperty = schema !== null && Object.getOwnPropertyNames(schema).some(propertyName => schema[propertyName].key);
			if (!hasKeyProperty) {
				delete definition[x];
			}
		}
	}

	private static removeUnknownNavigationProperties(definition: any): void {
		for (const x of Object.getOwnPropertyNames(definition)) {
			const isNavigationProperty = definition[x].type && typeof definition[x].type === "string"
				&& (definition[x].type as string).includes("Crm.Offline.DatabaseModel");
			if (!isNavigationProperty) {
				continue;
			}
			const schema = HelperDatabase.getSchema((definition[x].type as string).substring(26));
			const hasKeyProperty = schema !== null && Object.getOwnPropertyNames(schema).some(propertyName => schema[propertyName].key);
			if (!hasKeyProperty) {
				delete definition[x];
			}
		}
	}

	private static createStorage(storageKey: string): void {
		const beforeReadHandler = (items: any, query: { defaultType?: any; expression: any; context: any; }) => {
			//var sqlText = query.context.storageProvider._compile(query).sqlText;
			HelperDatabase.applyGlobalFilters(query);
			$(window.Helper.Offline).trigger("beforeRead", {table: query.defaultType.name, query: query});
		};

		function afterReadHandler(items: any, entitySet: any, query: { defaultType: { name: any; }; }): void {
			//var sqlText = query.context.storageProvider._compile(query).sqlText;
			$(window.Helper.Offline).trigger("afterRead", {table: query.defaultType.name, query: query});
		}

		const schema = HelperDatabase.getSchema(storageKey);
		const hasKeyProperty = Object.getOwnPropertyNames(schema).some(propertyName => schema[propertyName].key);
		if (!hasKeyProperty) {
			return;
		}
		HelperDatabase.replaceArrayDefaultValues(schema);
		HelperDatabase.removeUnknownCollectionProperties(schema);
		HelperDatabase.removeUnknownNavigationProperties(schema);
		$data.Entity.extend("Crm.Offline.DatabaseModel." + storageKey, schema);
		const indices = HelperDatabase.getIndices(storageKey);

		indices.unshift({
			name: storageKey + "Key",
			keys: [HelperDatabase.getKeyPropertyFromDbSchema(storageKey)],
			unique: true
		});
		HelperDatabase.dbDefinition[storageKey] = {
			type: $data.EntitySet,
			elementType: namespace("Crm.Offline.DatabaseModel")[storageKey],
			indices: indices,
			beforeRead: beforeReadHandler,
			afterRead: afterReadHandler
		};
	}

	public static createInstance(model: string, plugin: string, storageKey: string = null): KnockoutObservable<any> {
		storageKey = storageKey || plugin.replace(".", "") + "_" + model;
		return window.database[storageKey][storageKey].create().asKoObservable();
	}

	public static getDefinitions(): JQuery.PromiseBase<any[], never, never, never, never, never, never, never, never, never, never, never> {
		return HelperDatabase.getRawDefinitions().then(results => {
			for (const [complexTypeName, complexTypeDefinition] of Object.entries(results.complexTypes || {})) {
				HelperDatabase.replaceArrayDefaultValues(complexTypeDefinition);
				HelperDatabase.removeUnknownCollectionProperties(complexTypeDefinition);
				HelperDatabase.removeUnknownNavigationProperties(complexTypeDefinition);
				$data.Entity.extend(complexTypeName, complexTypeDefinition as any[]);
			}
			const definitions = [];
			for (const [pluginName, pluginDefinitions] of Object.entries(results.tables || {})) {
				for (const [typeName, columns] of Object.entries(pluginDefinitions || {})) {
					definitions.push({
						columns: columns,
						model: typeName,
						plugin: pluginName,
						table: pluginName.replace(/\./g, "") + "_" + typeName
					});
				}
			}
			return definitions;
		});
	}

	private static createSchemaFromDefinitions(): JQuery.PromiseBase<void, never, never, never, never, never, never, never, never, never, never, never> {
		return HelperDatabase.getDefinitions().then(definitions => {
			definitions.forEach(definition => {
				HelperDatabase.replaceArrayDefaultValues(definition.columns);
				($data as any).define(definition.plugin + "." + definition.model, definition.columns);
				HelperDatabase.registerTable(definition.table, definition.columns);
			});
		});
	}

	static getODataStorageOptions(): oDataStorageOption {
		return {
			provider: "oData",
			oDataServiceHost: resolveUrl("~/api"),
			queryCache: false,
			UpdateMethod: "MERGE"
		};
	}

	static getStorageOptions(): StorageOptions {
		return HelperDatabase.getODataStorageOptions();
	}

	private static async checkIfTransient(element: any, storageKey: string): Promise<boolean> {
		const keyProperty = HelperDatabase.getKeyProperty(storageKey);
		const id = element.innerInstance[keyProperty];
		if (id === 0 || id === "00000000-0000-0000-0000-000000000000") {
			return true;
		}
		return await window.database[storageKey].filter("it." + keyProperty + " == this.id", {id}).count() === 0;
	}

	private static dispatchDatabaseInitializedEvent(): void {
		document.dispatchEvent(new Event("DatabaseInitialized"));
	}

	// indexedDb provider has a bug regarding multi-entry indices. plus IE currently doesn't support multi-entry indices at all
	private static isMultiEntryIndexSupported = () => HelperDatabase.getStorageOptions().provider !== "indexedDb";

}

function setupDatabase() {
	if (!window.hasOwnProperty("database")) {
		Object.defineProperty(window, "database", {
			get: () => namespace("window.Crm.Offline").Database || null
		});
	}
}

// @ts-ignore
(window.Helper = window.Helper || {}).Database = HelperDatabase;
setupDatabase();
export {HelperDatabase};

